<template>
  <b-row>
    <b-col cols="12">
      <h2>Visit</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <datatable :items="items" :fields="fields">
        <template slot="filters">
          <b-col cols="3" class="my-3">
            <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-add-document-admin
            >
              Añadir nueva visita
            </b-button>
          </b-col>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons">
            <span
              title="ver"
              @click="show(item.uuid)"
              :id="'_show_button_' + item.uuid"
            >
              <i class="iconly-Light-Show"></i>
            </span>
            <!-- <span title="editar" @click="edit(  item.uuid  )"> <i class="iconly-Light-Edit mr-10"></i> </span> -->
            <span
              title="eliminar"
              @click="deletes(item.uuid)"
              :id="'_show_eliminar' + item.uuid"
            >
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
          </b-row>

          <b-tooltip :target="'_show_button_' + item.uuid" triggers="hover">
            Ver
          </b-tooltip>

          <b-tooltip :target="'_show_editar' + item.uuid" triggers="hover">
            Editar
          </b-tooltip>

          <b-tooltip :target="'_show_eliminar' + item.uuid" triggers="hover">
            Eliminar
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>
      </datatable>
    </div>

    <!-- create document -->
    <b-modal
      size="xl"
      id="modal-add-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nueva visita</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input
                v-model="documentCreate.name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Tipo de Documento">
              <b-form-select
                v-model="documentCreate.dni_type_uuid"
                :options="DniType"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            v-if="
              documentCreate.dni_type_uuid == 4 ||
              documentCreate.dni_type_uuid == 3
            "
          >
            <b-form-group label="RUT">
              <imask-input
                class="form-control"
                :value="documentCreate.dni"
                v-imask="mask"
                :unmask="true"
                @complete="onComplete"
                placeholder="00.000.000-00"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            v-if="
              documentCreate.dni_type_uuid != 4 &&
              documentCreate.dni_type_uuid != 3
            "
          >
            <b-form-group label="Documento">
              <b-form-input
                v-model="documentCreate.dni"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Comentario">
              <b-form-textarea
                v-model="documentCreate.comment"
                type="text"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="submitFile">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- edit document -->
    <!-- <b-modal
      size="xl"
      id="modal-edit-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-document-admin"
      modal-class="hp-profile-modal-1"
      @hide="modalClose"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nuevo producto</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
 
          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input v-model="documentCreate.title" type="text" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Contenido">
              <b-form-input v-model="documentCreate.content" type="text" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button 
              variant="primary"
              block
              @click="submitFileEdit"
            >
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
          
        </b-row>
      </form>
    </b-modal> -->
    <!-- View document detail -->
    <b-modal
      id="modal-detail-document-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-detail-document-admin"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Ver detalle de la visita</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input
                v-model="documentCreate.name"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="RUT">
              <b-form-input
                v-model="documentCreate.dni"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Comentario">
              <b-form-textarea
                v-model="documentCreate.comment"
                type="text"
                :disabled="true"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-detail-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import visits from "@/logic/functions/visits";
import dni from "@/logic/functions/dni";
import validate_dni from "@/logic/functions/users/validate_dni";
import { DateTime } from "luxon";
import { IMaskComponent, IMaskDirective } from "vue-imask";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    BPagination,
    datatable,
    "imask-input": IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      fields: [
        {
          key: "uuid",
          label: "Nombre",
          formatter: (value, key, item) => {
            return item.name;
          },
        },
        { key: "dni", label: "RUT" },
        {
          key: "created_at",
          label: "Fecha de visita",
          formatter: (value, key, item) => {
            return (
              DateTime.fromISO(item.created_at).toFormat("dd/MM/yyyy HH:mm ") +
              "hrs"
            );
          },
        },
        { key: "actions", label: "Acciones" },
      ],
      Actions: [],
      items: [],
      loadNewUsers: [],
      codeText: "",
      codeActive: false,
      codeActiveClass: false,
      flags: {
        formUpload: false,
        fileUpload: false,
      },
      DniType: [],
      createSales: {},
      documentCreate: {
        dni_type_uuid: 3,
      },
      mask: {
        mask: "00.000.000-{[*]}",
        lazy: false,
      },
      dniIsValid: false,
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
    };
  },
  methods: {
    onComplete(e) {
      let dni = e.detail._value.replace(/\./g, "");
      if (validate_dni.validaRut(dni)) {
        this.dniIsValid = true;
        this.documentCreate.dni = e.detail._value;
        e.target.style.border = "none";
      } else {
        e.target.style.border = "3px solid #FF0000";
      }
    },
    show(uuid) {
      this.documentCreate = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.$bvModal.show("modal-detail-document-admin");
    },
    edit(uuid) {
      this.documentCreate = {};
      this.documentCreate = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.$bvModal.show("modal-edit-document-admin");
    },
    deletes(uuid) {
      let items = this.items;
      visits
        .delete(uuid)
        .then((response) => {
          const index = items.findIndex((item) => item.uuid === uuid);
          if (~index) {
            items.splice(index, 1);
          }
          sweet.ToastMsg("", "success", "visita eliminada");
        })
        .catch((error) => {});
    },
    async documents(page = 0) {
      await visits
        .list({
          params: {
            limit: 5,
            page: 0,
            department_uuid: this.$route.params.id,
          },
        })
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {});
    },
    async submitFile() {
      this.dniIsValid =
        this.documentCreate.dni_type_uuid == 3 ||
        this.documentCreate.dni_type_uuid == 4
          ? this.dniIsValid
          : true;
      if (this.dniIsValid) {
        await visits
          .create({
            comment: this.documentCreate.comment,
            department_uuid: this.$route.params.id,
            dni: this.documentCreate.dni,
            dni_type_uuid: this.documentCreate.dni_type_uuid.toString(),
            name: this.documentCreate.name,
          })
          .then((response) => {
            this.documents();
            this.$bvModal.hide("modal-add-document-admin");
            sweet.ToastMsg("", "success", "Visita creada");
          })
          .catch((error) => {
            this.flags.fileUpload = false;
            sweet.ToastMsg("", "error", "Visita no creada");
          });
      } else {
        this.$bvModal.hide("Rut no valido");
      }
    },
    async listDniType() {
      await dni
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.DniType.push({ value: value.uuid, text: value.name });
          });
        })
        .catch((error) => {});
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;

      this.documents(page);
    },
    maskdni(e) {
      var inputValue = e;

      var dni = inputValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{1})/,
        "$1.$2.$3-$4"
      );

      this.documentCreate.dni = dni;
    },
  },
  beforeMount() {},
  mounted() {
    this.listDniType();
    this.documents();
  },
};
</script>
